/**
 * @generated SignedSource<<d508534a00e431466481dc771c1b05e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionNewsletterWidgetQuery$data = {
  readonly section: {
    readonly relatedNewsletters: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"helpersMassageToNewsletterItemNewsletter">;
    } | null | undefined> | null | undefined;
  };
  readonly " $fragmentType": "sectionNewsletterWidgetQuery";
};
export type sectionNewsletterWidgetQuery$key = {
  readonly " $data"?: sectionNewsletterWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionNewsletterWidgetQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionNewsletterWidgetQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Newsletter",
          "kind": "LinkedField",
          "name": "relatedNewsletters",
          "plural": true,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersMassageToNewsletterItemNewsletter",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entityId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "alternativeName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FormattedValue",
                  "kind": "LinkedField",
                  "name": "description",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FormattedValue",
                  "kind": "LinkedField",
                  "name": "summary",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FormattedValue",
                  "kind": "LinkedField",
                  "name": "homepageDescription",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "59f9f426fabcef9f7232d052de48260a";

export default node;
