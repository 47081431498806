/**
 * @generated SignedSource<<e440bd85dcbb68a5523827c60ae2c420>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type postMagazineNewsletter$data = {
  readonly newsletter: {
    readonly description: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly shortDescription: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly summary: {
      readonly text: string | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "postMagazineNewsletter";
};
export type postMagazineNewsletter$key = {
  readonly " $data"?: postMagazineNewsletter$data;
  readonly " $fragmentSpreads": FragmentRefs<"postMagazineNewsletter">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "postMagazineNewsletter",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "322743"
          }
        }
      ],
      "concreteType": "Newsletter",
      "kind": "LinkedField",
      "name": "newsletter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "shortDescription",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "summary",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "newsletter(filter:{\"entityId\":\"322743\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a117de0681e879d3059652764c3bec35";

export default node;
