import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontIndivisibleVariable, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import {
  MenuItem,
  StyledSwiper,
  StyledSwiperSlide,
} from "scmp-app/components/entity-oneline-menu/styles";

import IconArrowNextMobile from "./icon-arrow-next-mobile.svg?url";
import IconArrowPreviousMobile from "./icon-arrow-previous-mobile.svg?url";
import PostMagazineSvg from "./logo-post-magazine.svg";
import ScmpSvg from "./logo-scmp.svg";

export const Container = styled.div`
  display: grid;
  grid:
    "logo tag-line" min-content
    "sub-section sub-section" min-content / minmax(205px, 1fr) auto;

  inline-size: 100%;
  padding-block-start: 32px;
  padding-inline: 20px;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "logo tag-line" min-content
      "sub-section sub-section" min-content / 1fr auto;

    padding-block-start: 20px;
    padding-inline: 40px;
  }

  ${theme.breakpoints.up("desktop")} {
    grid:
      "logo . tag-line" min-content
      "sub-section . tag-line" min-content / 1fr 32px auto;

    padding-block-start: 40px;
  }

  ${StyledSwiper} {
    .swiper-button-next,
    .swiper-button-prev {
      block-size: 15.6px;
      ${theme.breakpoints.up("tablet")} {
        block-size: 16.8px;
      }
      ${theme.breakpoints.up("desktop")} {
        block-size: 19.2px;
      }
    }
    .swiper-button-next {
      background-image: url(${IconArrowNextMobile});
    }
    .swiper-button-prev {
      background-image: url(${IconArrowPreviousMobile});
    }
    span {
      font-size: 13px;
      line-height: 15.6px;
      ${theme.breakpoints.up("tablet")} {
        font-size: 14px;
        line-height: 16.8px;
      }
      ${theme.breakpoints.up("desktop")} {
        line-height: 19.2px;
      }
    }
  }
`;

export const LogoContainer = styled.div`
  grid-area: logo;

  display: flex;
  flex-direction: column;
`;

export const LogoScmpSvg = styled(ScmpSvg)`
  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: block;
  }
`;

export const LogoPostMagazine = styled(PostMagazineSvg)`
  inline-size: 176px;
  block-size: 42.73px;
  margin-block-start: 20px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 270px;
    block-size: 65px;
  }
  ${theme.breakpoints.up("desktop")} {
    inline-size: 360px;
    block-size: 87px;
  }
`;

const menuItemStyle = css`
  font-weight: 500;
  font-size: 13px;
  font-family: ${fontIndivisibleVariable};
  text-transform: uppercase;
  font-variation-settings: "wght" 500;

  ${theme.breakpoints.up("tablet")} {
    font-size: 16px;
  }
`;

export const StyledBaseLink = styled(BaseLink)`
  span {
    ${menuItemStyle}
  }
`;

export const StyledEntityOnelineMenu = styled(EntityOnelineMenu)`
  grid-area: sub-section;

  margin-block-start: 40px;
  ${MenuItem} {
    ${menuItemStyle}
  }

  ${StyledSwiperSlide} {
    &.swiper-slide {
      &:not(:last-child) {
        margin-inline-end: 13px;
        ${theme.breakpoints.up("tablet")} {
          margin-inline-end: 14px;
        }
        ${theme.breakpoints.up("desktop")} {
          margin-inline-end: 28px;
        }
      }
    }
  }
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 32px;
  }

  ${MenuItem} {
    &[data-active="true"] {
      font-variation-settings: "wght" 700;
    }
  }
`;

export const TagLine = styled.div`
  grid-area: tag-line;

  display: flex;
  align-items: center;

  ${theme.breakpoints.up("desktop")} {
    align-items: stretch;
  }
`;

export const TagLineWrapper = styled.div`
  inline-size: 100%;
  padding-inline-start: 14px;
  border-inline-start: 1px solid #777777;

  ${theme.breakpoints.up("tablet")} {
    padding-inline-start: 18px;
  }

  ${theme.breakpoints.up("desktop")} {
    padding-inline-start: 32px;
  }
`;
