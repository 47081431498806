/**
 * @generated SignedSource<<f612060c054c5629cb618da0f5ed9e6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type magazinesStyleCardContentItemContent$data = {
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"topicLinkTopic">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "helpersCheckIsMainTopicContent" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "magazinesStyleCardContentItemContent";
};
export type magazinesStyleCardContentItemContent$key = {
  readonly " $data"?: magazinesStyleCardContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"magazinesStyleCardContentItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "magazinesStyleCardContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicLinkTopic"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsMainTopicContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x1200",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize500x500",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "d8e1a89687a252d13cfaea8fa852c160";

export default node;
