export type InlineAdSlotSchema = {
  attribs: InlineAdSlotSchemaAttributes;
  type: typeof InlineAdSlotSchemaType;
};

export const InlineAdSlotSchemaType = "inline-ad-slot";

export type InlineAdSlotSchemaAttributes = {
  infiniteAdsAfterNumber?: string;
  tag: string;
};

export type InlineAdSlotSchemaRow = InlineAdSlotSchema & {
  row: number;
};

export function defineInlineAdSlotSchema(options: {
  infiniteAdsAfterNumber?: string;
  row: number;
  tag: string;
}): InlineAdSlotSchemaRow {
  const { infiniteAdsAfterNumber, row, tag } = options;
  return {
    attribs: {
      infiniteAdsAfterNumber,
      tag,
    },
    row,
    type: InlineAdSlotSchemaType,
  };
}

export function isInlineAdSlotSchema(node: SchemaNode): node is InlineAdSlotSchema {
  return node.type === InlineAdSlotSchemaType;
}
