import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import Logo from "./icon-logo-style.svg";

export const Container = styled.div``;

export const HeaderLogo = styled(Logo)`
  display: inline-block;

  inline-size: 120px;
  block-size: 75px;
  margin-block-end: 10px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 209px;
    block-size: 132px;
  }
  ${theme.breakpoints.up("desktop")} {
    inline-size: 278px;
    block-size: 176px;
  }
`;
