import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontBodoni, fontIndivisibleVariable, theme } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { SponsorHeadline } from "scmp-app/components/content/content-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import {
  HomeSponsorName,
  HomeSponsorTagWrapper,
  HomeSponsorType,
} from "scmp-app/components/sponsor-tag/styles";

export const Container = styled.div`
  position: relative;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  color: #111111;
  font-weight: 400;
  font-size: 24px;
  font-family: ${fontBodoni};
  line-height: 110%;
  letter-spacing: -0.72px;

  ${theme.breakpoints.up("tablet")} {
    font-size: 32px;
    letter-spacing: -0.96px;
  }
`;

type HeadlineProps = {
  $isSponsorArticle: boolean;
};

export const Headline = styled.div<HeadlineProps>`
  ${SponsorHeadline} {
    color: #111111;
    font-family: ${fontIndivisibleVariable};
    font-style: normal;
    font-variation-settings: "wght" 700;
  }

  ${({ $isSponsorArticle }) =>
    $isSponsorArticle &&
    css`
      font-size: 18px;
      line-height: 110%;
      letter-spacing: -0.36px;

      ${theme.breakpoints.up("tablet")} {
        font-size: 22px;
        letter-spacing: -0.44px;
      }

      ${theme.breakpoints.up("desktop")} {
        font-size: 24px;
        letter-spacing: -0.56px;
      }
    `}

  ${HomeSponsorType} ${HomeSponsorName} {
    font-size: 12px;
    letter-spacing: -0.012px;
  }

  ${Flag} {
    color: #111111;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)`
  aspect-ratio: 3/2;

  display: block;

  margin-block-end: 20px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 30px;
  }
`;

export const CoverImage = styled.div`
  position: relative;
  aspect-ratio: 3/2;

  inline-size: 100%;
  block-size: 100%;
`;

export const Topic = styled.div`
  > a {
    margin-block-start: 12px;

    color: #111111;
    font-weight: 500;
    font-size: 13px;
    font-family: ${fontIndivisibleVariable};
    line-height: normal;
    letter-spacing: -0.013px;
    text-transform: uppercase;

    font-variation-settings: "wght" 500;

    ${theme.breakpoints.up("tablet")} {
      margin-block-start: 16px;

      font-size: 16px;
      letter-spacing: -0.016px;
    }
  }
`;

export const SponsorContainer = styled.div`
  margin-block-start: 12px;

  ${HomeSponsorTagWrapper} {
    padding: 0;

    border: 0;
  }

  ${HomeSponsorType} {
    margin-block-end: 0;

    color: #000000;
    font-weight: 400;
    font-size: 16px;
    font-family: ${fontIndivisibleVariable};
    font-style: normal;
    line-height: 19.2px;
    letter-spacing: -0.016px;
    font-variation-settings: "wght" 500;
  }

  ${HomeSponsorName} {
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    font-variation-settings: "wght" 900;

    font-family: ${fontIndivisibleVariable};
    font-style: normal;
    line-height: 19.2px;
    letter-spacing: -0.016px;
  }
`;
