/**
 * @generated SignedSource<<58d9f0986ddbf4d8639c7170e3ebf6d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contextsSectionContextProviderSection$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseSectionBaseAdvertisingInfoSection">;
  readonly " $fragmentType": "contextsSectionContextProviderSection";
};
export type contextsSectionContextProviderSection$key = {
  readonly " $data"?: contextsSectionContextProviderSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"contextsSectionContextProviderSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contextsSectionContextProviderSection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseSectionBaseAdvertisingInfoSection"
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "6dbccc4993bd761a957883a36b1e8ea6";

export default node;
