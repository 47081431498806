import { notEmpty } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import type { ResponsiveVariants } from "shared/lib/styles";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import type { postMagazinePrimaryContent$key } from "scmp-app/queries/__generated__/postMagazinePrimaryContent.graphql";

import {
  Container,
  CoverImage,
  Headline,
  SponsorContainer,
  StyledCoverEntityLink,
  StyledEntityLink,
  Topic,
} from "./styles";

export type Props = ContentItemRenderVariantProps & {
  imageResponsiveVariants: ResponsiveVariants<"size540x360" | "size768x768" | "size1200x800">;
  reference: postMagazinePrimaryContent$key;
};

const Component = forwardRef<HTMLDivElement, Props>(
  (
    { className, imageResponsiveVariants, onClick, reference: reference_, topicLinkVariant },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment postMagazinePrimaryContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageSize540x360: true
              withCoverImageSize768x768: true
              withCoverImageSize1200x800: true
              withHeadline: true
              withTopicLink: true
              withSponsorTag: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, topicLinkVariant);
    const topic = providers.topicLink?.({ preferShortName: true });
    const headline = providers.headline({ hideTooltip: true, preferSocialHeadline: true });
    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);

    return (
      <Container className={className} ref={reference}>
        <StyledCoverEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: imageResponsiveVariants,
            })}
          </CoverImage>
        </StyledCoverEntityLink>
        <div>
          <StyledEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <Headline $isSponsorArticle={isSponsorArticle}>{headline}</Headline>
          </StyledEntityLink>
          <Topic>{topic}</Topic>
          {isSponsorArticle && (
            <SponsorContainer>{providers.sponsorTag?.({ variant: "home" })}</SponsorContainer>
          )}
        </div>
      </Container>
    );
  },
);

Component.displayName = "ContentItemPostMagazinePrimary";

export const ContentItemPostMagazinePrimary = Component;
