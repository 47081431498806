import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { tracking } from "shared/data";

import { LatestDiscoveryWidget } from "scmp-app/components/rhs-module/latest-discovery-widget";
import type { chinaFutureTechDiscoveryWidgetQuery$key } from "scmp-app/queries/__generated__/chinaFutureTechDiscoveryWidgetQuery.graphql";

import {
  HighlightsFrom,
  StyledSectionTermLogo,
  TextContainer,
  Title,
  TitleContainer,
} from "./styles";

type Props = {
  className?: string;
  reference: chinaFutureTechDiscoveryWidgetQuery$key;
};

export const ChinaFutureTechDiscoveryWidget: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment chinaFutureTechDiscoveryWidgetQuery on Query {
        chinaFutureTechSection: section(filter: { entityId: "519735" }) {
          ...latestDiscoveryWidgetSection
          ...sectionTermLogoSection
          urlAlias
          name
        }
        chinaFutureTechQueue: queue(filter: { name: "section_top_519735" }) {
          ...latestDiscoveryWidgetQueue
        }
      }
    `,
    reference,
  );
  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.ChinaFutureTech,
      }}
    >
      <LatestDiscoveryWidget
        className={className}
        overrideMoreLinkLabel={`MORE IN ${data.chinaFutureTechSection.name}`}
        queueReference={data.chinaFutureTechQueue}
        sectionReference={data.chinaFutureTechSection}
        titleComponent={
          <TitleContainer pathname={data.chinaFutureTechSection?.urlAlias}>
            <StyledSectionTermLogo reference={data.chinaFutureTechSection} />
            <TextContainer>
              <HighlightsFrom>HIGHLIGHTS FROM</HighlightsFrom>
              <Title>{data.chinaFutureTechSection.name}</Title>
            </TextContainer>
          </TitleContainer>
        }
        variant="image-first"
      />
    </BaseLinkContextProvider>
  );
};

ChinaFutureTechDiscoveryWidget.displayName = "ChinaFutureTechDiscoveryWidget";
