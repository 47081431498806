import merge from "deepmerge";
import { useAtomValue } from "jotai";
import type { NextSeoProps } from "next-seo";
import { useEffect } from "react";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { defaultAdditionalMetaTags } from "shared/components/meta";
import { DefaultTitleTemplate } from "shared/components/meta/consts";
import {
  useDeepLinkTags,
  useDynamicMetaTags,
  useKeywordMetaTags,
} from "shared/components/meta/hooks";
import { useCanonicalUrl } from "shared/hooks";
import { getRssFeedLink } from "shared/lib/utils";

import type { Props as PageSeoProps } from "scmp-app/components/meta";
import { PageSeo } from "scmp-app/components/meta";
import { trackingAtom } from "scmp-app/components/tracking";
import { getIsSubSection } from "scmp-app/lib/router/helpers";
import type { sectionSeoSection$key } from "scmp-app/queries/__generated__/sectionSeoSection.graphql";

import { useSeoImage } from "./hook";

type Props = PageSeoProps & {
  reference: sectionSeoSection$key;
};

export const SectionSeo: FunctionComponent<Props> = ({ reference: reference_, ...extraProps }) => {
  const section = useFragment(
    graphql`
      fragment sectionSeoSection on Section {
        name
        description {
          text
        }
        entityId
        entityUuid
        image(filter: { type: OG }) {
          url
        }
        parentSection
        urlAlias
        ...hooksUseDeepLinkTagsBase
        ...hooksUseDynamicMetaTags
      }
    `,
    reference_,
  );

  const rssFeedLink = getRssFeedLink(section?.entityId);

  const metaTags = useDynamicMetaTags(section);
  const keywordTags = useKeywordMetaTags(metaTags.controlled.keywords ?? section.name);
  const deepLinkTags = useDeepLinkTags(section);

  const titleTemplate = metaTags.controlled.title ? "%s" : DefaultTitleTemplate;
  const title = metaTags.controlled.title ?? section.name;

  const description = metaTags.controlled.description ?? section.description?.text ?? undefined;

  const { canonicalUrl } = useCanonicalUrl(section.urlAlias);

  const ogImageUuid = getIsSubSection({
    entityId: section.entityId,
    parentSection: section.parentSection,
  })
    ? section.parentSection
    : section.entityUuid;

  const sectionOgImage = useSeoImage(ogImageUuid);

  const openGraph: NextSeoProps["openGraph"] = { description, title, url: canonicalUrl };
  if (section.image?.url) {
    openGraph.images = [{ url: section.image.url }];
  } else if (sectionOgImage) {
    openGraph.images = [{ url: sectionOgImage }];
  }

  const defaultSectionProps: NextSeoProps = {
    additionalLinkTags: [rssFeedLink],
    additionalMetaTags: [...keywordTags, ...deepLinkTags, ...defaultAdditionalMetaTags],
    canonical: canonicalUrl,
    description,
    openGraph,
    title,
    titleTemplate,
  };

  const mergedProps = merge(defaultSectionProps, extraProps);

  const { trackPageView } = useAtomValue(trackingAtom);

  // TODO: `slideIndex` & `name` needed later when implementing other section pages with slide
  useEffect(() => {
    const { entityId, entityUuid, name, urlAlias } = section;
    trackPageView?.({
      contentType: "Section",
      entityId,
      entityUuid,
      page: "Section",
      path: urlAlias,
      primarySection: name,
      sections: name,
    });
  }, [trackPageView, section]);

  return <PageSeo {...mergedProps} />;
};

SectionSeo.displayName = "SectionSeo";
