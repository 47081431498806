import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { SectionTermLogo } from "scmp-app/components/home/term-logo/section";

export const StyledSectionTermLogo = styled(SectionTermLogo)`
  inline-size: 40px;
  block-size: 40px;
  margin-inline: 0;
`;

export const TitleContainer = styled(BaseLink)`
  display: flex;
  gap: 12px;
  align-items: flex-start;

  margin-block-end: 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HighlightsFrom = styled.div`
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  text-transform: uppercase;

  opacity: 0.5;
`;

export const Title = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  text-transform: uppercase;
`;
